<template>
  <v-card>
    <v-tabs v-model="tab" background-color="primary" colo="terceira" centered dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab class="secondary--text" href="#tab-1">
        Contratos
        <v-icon color="secondary">mdi-briefcase </v-icon>
      </v-tab>

      <v-tab class="secondary--text button" href="#tab-2">
        Poder Criativo
        <v-icon color="secondary">mdi-pencil-ruler</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="1" :value="'tab-' + 1">
        <v-card flat>
          <v-card-text>
            <v-container fluid fill-height class="primary">
              <v-row justify="center" class="fill-height">
                <v-col cols="12" sm="auto" class="pa-5 pa-sm-15">
                  <h3 class="u-h3 text-center">
                    Links de projetos desenvolvidos sem contrato de
                    confidencialidade/2021-2024.
                  </h3>
                  <h4 class="u-h4 u-flex my-2 my-md-4">
                    <p class="u-obs mb-0">Obs: Todos os nossos trabalhos dispõem da nossa ass. 'AvizVFX' no footer.</p>
                  </h4>

                  <!-- BOX 1 NUTRIMIX-->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://nutrimix.net"
                        target="_black">nutrimix.net</a>(Maior Distribuidora Nestlé no RJ)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao">
                        Site e sistemas privados para comunicação sell-out/Nestlé, gestão de licitações e controle de
                        pregões com órgãos públicos federais e municipais.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 1 -->





                  <!-- BOX 2 NUTRIPLAN -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://nutriplanweb.com.br"
                        target="_black">nutriplanweb.com.br</a>(Do grupo Nutrimix - Nutriplan 2° Maior Distribuidor
                      Nestlé
                      em atendimento direto a hospitais, clínicas e escolas)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao">
                        Site e sistemas para gestão de vendas e
                        insights com automações de IA.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 2 -->




                  <!-- BOX 3 SABRA SEGURANÇA -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://sabraseguranca.com.br"
                        target="_black">sabraseguranca.com.br</a> (Empresa Israelense de Segurança Eletrônica)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao">
                        Site e aplicações para automação de atendimento e gestão de vendas.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 3 -->


                  <!-- BOX 4 VITALABO -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://vitalabo.com.br"
                        target="_black">vitalabo.com.br</a> (Clínica de Exames Laboratoriais)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao">
                        Site e aplicação para gestão de resultados de exames.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 4 -->


                  <!-- BOX 5 ITABORAÍ EM FOCO -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://itaboraiemfoco.com.br"
                        target="_black">itaboraiemfoco.com.br</a> (Maior Canal de Notícias de Itaboraí)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Portal de Notícias(60mil acessos semanalmente), DBA, SEO e especialista em
                        crescimento de audiência nas mídias Facebook/Instagram.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 5 -->

                  <!-- BOX 6 TORRE ONLINE -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://torreonline.com.br/"
                        target="_black">torreonline.com.br</a> (Empresa de telecomunicações no RJ, atende BAND, TIM,
                      CLARO
                      E
                      PF)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Site, Campnhas ADS, Microsserviços e aplicação para automação de
                        atendimento.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 6 -->

                  <!-- BOX 7 Clínica Polimédica -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://clinicapolimedica.com.br"
                        target="_black">clinicapolimedica.com.br</a> (Rede de Clínicas Médica)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Site, SEO, Campanhas e Microsserviços.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 7 -->


                  <!-- BOX 8 Colegio CCM -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://colegioccm.net/"
                        target="_black">colegioccm.net</a> (Rede de Cólegios e Facudade EAD)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Site, SEO, campanhas e aplicações para gestão.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 8 -->


                  <!-- BOX 9 Metallider -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://metallider.ind.br"
                        target="_black">metallider.ind.br</a> (Distribuidor Nacional de Peças Automotivas)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Site e aplicações para gestão B2B.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 9 -->


                  <!-- BOX 10 Fazenda De la Vega -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://fazendadelavega.com.br"
                        target="_black">fazendadelavega.com.br</a> (Fazenda Modelo de Produtos orgânicos)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Site e aplicação para gestão de produtos.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 10 -->


                  <!-- BOX 12 Cachaça De la Vega -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://cachacadelavega.com.br"
                        target="_black">cachacadelavega.com.br</a> (Cachaça Premium)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Site e aplicação para automação de pedidos.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 12 -->

                  <!-- BOX 13 Procis -->
                  <!-- <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://procis.com.br/"
                        target="_black">procis.com.br</a> (Distribuidora de Equipamentos de Segurança - Empresa
                      Israelense)
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Sistemas para gestão interna e externa, Microsserviços e E-commerce (em
                        desenvolvimento)
                      </p>
                    </div>
                  </div>
                  <br /> -->
                  <!-- FIM BOX 13 -->

                  <!-- BOX 14 LARAH -->
                  <div class="c-box">
                    <h4 class="u-h4">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://larah.app.avizvfx.com.br/"
                        target="_black">larah.app</a>
                      (Distribuidora mundial de cabelos naturais em Israel)
                      <span>
                        <v-img max-height="30" max-width="30" src="files/bandeiras/israel.png"
                          class="c-bandeira"></v-img>
                      </span>
                      <span class="c-dev">EM DESENVOLVIMENTO</span>
                    </h4>
                    <div class="c-box-desc">
                      <h5 class="u-h5">DESENVOLVIMENTO FULL STACK DE:</h5>
                      <p class="u-descricao"> Catálogo Digital V.1 e aplicação para automação de atendimento.
                      </p>
                    </div>
                  </div>
                  <br />
                  <!-- FIM BOX 14 -->

                  <hr class="u-line" />
                  <h3 class="u-h4">
                    <strong>SITES e Sistemas de Hotéis</strong> (Contratado como
                    terceirizado por outras empresas) <br />
                    <div class="py-2">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://kristieresort.com.br"
                        target="_black">kristieresort.com.br</a>
                    </div>
                    <div class="py-2">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://vitoriahoteis.com.br"
                        target="_black">vitoriahoteis.com.br</a>
                    </div>
                    <div class="py-2">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://lazulihotel.com.br"
                        target="_black">lazulihotel.com.br</a>
                    </div>
                    <div class="py-2">
                      <v-icon left>mdi-vector-link</v-icon>
                      <a class="white--text pr-2 text-uppercase" href="https://solarrayan.com/"
                        target="_black">solarrayan.com</a>
                      <span>
                        <v-img max-height="30" max-width="30" src="files/bandeiras/argentina.png"
                          class="c-bandeira"></v-img>
                      </span>
                    </div>
                    <div class="py-2">
                      <v-icon left>mdi-movie-play</v-icon>
                      <a class="white--text pr-2 text-uppercase"
                        href="https://drive.google.com/drive/folders/1hIawn4BxARKKlXmJObyTo2gOWBvOetv1?usp=sharing"
                        target="_black">ALGUNS TIMELAPSE DE SISTEMAS E APPS</a>
                    </div>
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="2" :value="'tab-' + 2">
        <v-card-text>
          <Galeria></Galeria>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Galeria from '@/components/Galeria.vue'
export default {
  name: "ContratosAviz",
  components: { Galeria },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style>
.c-bandeira {
  max-height: 30px;
  max-width: 30px;
  display: inline-flex;
}

.c-dev {
  display: inline-flex;
  background: #02ff00;
  color: #161616;
  font-weight: 900;
  padding: 5px;
  border-radius: 2px;
  margin-top: 9px;
  width: 368px;
}

.u-obs {
  text-align: right;
  background: rgb(255 255 255 / 8%);
  padding: 5px;
  border-radius: 4px;
}

.c-col__principal {
  height: 100vh;
  overflow-y: scroll;
}

/*ScrolBar*/

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #858585;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #858585;
}

::-webkit-scrollbar-track {
  background: #858585;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #858585;
}

::-webkit-scrollbar-track:active {
  background: #858585;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Scroll bar */
div#ascrail2000 {
  background: #858585 !important;
}

.nicescroll-cursors {
  background-color: #0e0e0e !important;
}

.u-line {
  border: solid 1px #3c3c3c;
  margin-bottom: 15px;
}
</style>